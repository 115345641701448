import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Header from "../ui/layout/Header";
import Footer from "../ui/layout/Footer";
import Reviews from "../ui/Reviews";
import Readings from "../ui/Readings";
import ToReads from "../ui/ToReads";
import CombinedFeed from "../ui/CombinedFeed";
import Review from "../ui/Review";
import Reading from "../ui/Reading";
import ToRead from "../ui/ToRead";
import ReviewModal from "../ui/ReviewModal";
import ReadingModal from "../ui/ReadingModal";
import ToReadModal from "../ui/ToReadModal";
import Loader from "../ui/Loader";
import { Button } from "../ui/shadcn/Button";
import ShopifyBuyButton from "../ui/ShopifyBuyButton";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
  } from "../ui/shadcn/Popover"  
import useStore from "../../store/store";
import { Tabs, TabsListComponent, TabsTrigger, TabsContent } from "../ui/shadcn/Tabs";
import "../../styles/index.css";

const DEFAULT_REVIEWS = null;
const DEFAULT_READINGS = null;
const DEFAULT_TO_READS = null;
const DEFAULT_EXPLORE_REVIEWS = null;
const DEFAULT_EXPLORE_READINGS = null;
const DEFAULT_EXPLORE_TO_READS = null;
const DEFAULT_SELECTED_REVIEW = null;
const DEFAULT_SELECTED_READING = null;
const DEFAULT_SELECTED_TO_READ = null;
const DEFAULT_SELECTIONS = [];
const DEFAULT_GOOGLE_BOOKS_SEARCH = "";
const DEFAULT_GOOGLE_BOOKS_BOOKS = [];
const DEFAULT_RATING = 0;
const DEFAULT_CONTENT = "";
const DEFAULT_READING_CONTENT = "";
const DEFAULT_READ_DATE = null;
const DEFAULT_MODAL_STEP = 1;
const DEFAULT_IS_MODAL_OPEN = false;
const DEFAULT_IS_DATA_LOADING = false;
const DEFAULT_IS_READING_MODAL_OPEN = false;
const DEFAULT_READING_MODAL_STEP = 1;
const DEFAULT_IS_TO_READ_MODAL_OPEN = false;
const DEFAULT_TO_READ_MODAL_STEP = 1;
const DEFAULT_TO_READ_CONTENT = "";
const DEFAULT_START_DATE = null;

const Feed = () => {
    const [feedPosts, setFeedPosts] = useState([]);
    const [reviews, setReviews] = useState(DEFAULT_REVIEWS);
    const [readings, setReadings] = useState(DEFAULT_READINGS);
    const [toReads, setToReads] = useState(DEFAULT_TO_READS);
    const [exploreReviews, setExploreReviews] = useState(DEFAULT_EXPLORE_REVIEWS);
    const [exploreReadings, setExploreReadings] = useState(DEFAULT_EXPLORE_READINGS);
    const [exploreToReads, setExploreToReads] = useState(DEFAULT_EXPLORE_TO_READS);
    const [selectedReview, setSelectedReview] = useState(DEFAULT_SELECTED_REVIEW);
    const [selectedReading, setSelectedReading] = useState(DEFAULT_SELECTED_REVIEW);
    const [selectedToRead, setSelectedToRead] = useState(DEFAULT_SELECTED_TO_READ);
    const [selections, setSelections] = useState(DEFAULT_SELECTIONS);
    const [googleBooksSearch, setGoogleBooksSearch] = useState(DEFAULT_GOOGLE_BOOKS_SEARCH);
    const [googleBooksBooks, setGoogleBooksBooks] = useState(DEFAULT_GOOGLE_BOOKS_BOOKS);
    const [rating, setRating] = useState(DEFAULT_RATING);
    const [content, setContent] = useState(DEFAULT_CONTENT);
    const [readDate, setReadDate] = useState(DEFAULT_READ_DATE);
    const [modalStep, setModalStep] = useState(DEFAULT_MODAL_STEP);
    const [isModalOpen, setIsModalOpen] = useState(DEFAULT_IS_MODAL_OPEN);
    const [isReadingModalOpen, setIsReadingModalOpen] = useState(DEFAULT_IS_READING_MODAL_OPEN);
    const [readingModalStep, setReadingModalStep] = useState(DEFAULT_READING_MODAL_STEP);
    const [startDate, setStartDate] = useState(DEFAULT_START_DATE);
    const [readingContent, setReadingContent] = useState(DEFAULT_CONTENT);
    const [isToReadModalOpen, setIsToReadModalOpen] = useState(DEFAULT_IS_TO_READ_MODAL_OPEN);
    const [toReadModalStep, setToReadModalStep] = useState(DEFAULT_TO_READ_MODAL_STEP);
    const [toReadContent, setToReadContent] = useState(DEFAULT_TO_READ_CONTENT);
    const [isDataLoading, setIsDataLoading] = useState(DEFAULT_IS_DATA_LOADING);
    const { isUserLoading, user, getUser } = useStore((state) => ({
        isUserLoading: state.isUserLoading,
        user: state.user,
        getUser: state.getUser
    }));
    // This is for OBC IRL feed
    const [loadingObcIrl, setLoadingObcIrl] = useState(false);
    const [obcIrlPosts, setObcIrlPosts] = useState([]);
    const [pageObcIrl, setPageObcIrl] = useState(1);
    const [totalPagesObcIrl, setTotalPagesObcIrlPosts] = useState(1);
    // These are for explore feed pagination
    const [explorePosts, setExplorePosts] = useState([]); 
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    // These are for following feed pagination
    const [followingPosts, setFollowingPosts] = useState([]); 
    const [pageFollowing, setPageFollowing] = useState(1);
    const [totalPagesFollowing, setTotalPagesFollowing] = useState(1);
    const [loadingFollowing, setLoadingFollowing] = useState(false);
    // This is for snail mail feed
    const [loadingSnailMail, setLoadingSnailMail] = useState(false);
    const [snailMailPosts, setSnailMailPosts] = useState([]);
    const [pageSnailMail, setPageSnailMail] = useState(1);
    const [totalPagesSnailMail, setTotalPagesSnailMail] = useState(1);
    // This is for linking to a post directly 
    const { reviewId } = useParams();
    const { readingId } = useParams();
    const { toReadId } = useParams();
    const navigate = useNavigate();

    // Run all of these when the page loads
    useEffect(() => {
        console.log('Component mounted');
        getUser();
    }, []);

    useEffect(() => {
        console.log('Page state changed:', page);
        getAllPosts();
        getAllFollowingPosts();
        getAllObcIrlPosts();
        getAllSnailMailPosts();
    }, [page]);

    //This is for the SMS bringing you directly to the post if you liked or commented on it
    useEffect(() => {
        console.log("in sms use effect")
        console.log(reviewId);
        console.log(readingId);
        console.log(toReadId);

        if (reviewId) {
            console.log("in review id if")
            getReviewById(reviewId);
        } else if (readingId) {
            console.log("in reading id if")
            getReadingById(readingId);
        } else if (toReadId) {
            console.log("in to read id if")
            getToReadById(toReadId);
        }
    }, [reviewId, readingId, toReadId]);

    const getAllPosts = async () => {
        if (loading) return; 
        setLoading(true);
        try {
            try {
                const response = await axios.get(`/api/e/v1/social/get_explore_posts?page=${page}&limit=10`);
                setExplorePosts(prevPosts => [...prevPosts, ...response.data.posts]);
                setTotalPages(response.data.totalPages);
            } catch (err) {
                console.error('Failed to load posts', err);
            } finally {
                setLoading(false);
            }
        } catch (err) {
            console.error(err);
            toast.error("Error fetching posts");
        }
    };

    const handleScroll = () => {
        //if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || loading) return;
        if (page < totalPages) {
            setPage(page + 1);
        }
        else if (pageFollowing < totalPagesFollowing) {
            setPageFollowing(pageFollowing + 1);
        }
        else if (pageObcIrl < totalPagesObcIrl) {
            setPageObcIrl(pageObcIrl + 1);
        }
        else if (pageSnailMail < totalPagesSnailMail) {
            setPageSnailMail(pageSnailMail + 1);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loading, page, totalPages]);

    const getAllFollowingPosts = async () => {
        if (loadingFollowing) return; 
        setLoadingFollowing(true);
        try {
            try {
                const response = await axios.get(`/api/e/v1/social/get_following_posts?page=${page}&limit=10`);
                setFollowingPosts(prevPosts => [...prevPosts, ...response.data.posts]);
                setTotalPagesFollowing(response.data.totalPages);
            } catch (err) {
                console.error('Failed to load posts', err);
            } finally {
                setLoadingFollowing(false);
            }
        } catch (err) {
            console.error(err);
            toast.error("Error fetching posts");
        }
    };

    const getAllObcIrlPosts = async () => {
        if (loadingObcIrl) return; 
        setLoadingObcIrl(true);
        try {
            try {
                const response = await axios.get(`/api/e/v1/social/get_obc_irl_posts?page=${page}&limit=10`);
                setObcIrlPosts(prevPosts => [...prevPosts, ...response.data.posts]);
                setTotalPagesObcIrlPosts(response.data.totalPages);
            } catch (err) {
                console.error('Failed to load OBC IRL posts', err);
            } finally {
                setLoadingObcIrl(false);
            }
        } catch (err) {
            console.error(err);
            toast.error("Error fetching OBC IRL posts");
        }
    };

    const getAllSnailMailPosts = async () => {
        if (loadingSnailMail) return; 
        setLoadingSnailMail(true);
        try {
            try {
                const response = await axios.get(`/api/e/v1/social/get_snail_mail_posts?page=${page}&limit=10`);
                console.log("API Response:", JSON.stringify(response.data, null, 2));
                setSnailMailPosts(prevPosts => [...prevPosts, ...response.data.posts]);
                setTotalPagesSnailMail(response.data.totalPages);
            } catch (err) {
                console.error('Failed to load snail mail club posts', err);
            } finally {
                setLoadingSnailMail(false);
            }
        } catch (err) {
            console.error(err);
            toast.error("Error fetching snail mail posts");
        }
    };

    //These are to set selected reviews + make sure the back buttons work, too!
    useEffect(() => {
        if (reviews && reviewId) {
            let selectedReview = reviews.find(review => review._id === reviewId);
            if (selectedReview) {
                setSelectedReview(selectedReview);
            } else {
                getReviewById(reviewId);
            }
        } else {
            setSelectedReview(DEFAULT_SELECTED_REVIEW);
        }
    }, [reviews, reviewId]);

    useEffect(() => {
        if (readings && readingId) {
            let selectedReading = readings.find(reading => reading._id === readingId);
            if (selectedReading) {
                setSelectedReading(selectedReading);
            } else {
                getReadingById(readingId);
            }
        } else {
            setSelectedReading(DEFAULT_SELECTED_READING);
        }
    }, [readings, readingId]);

    useEffect(() => {
        if (toReads && toReadId) {
            let selectedToRead= toReads.find(toRead => toRead._id === toReadId);
            if (selectedToRead) {
                setSelectedToRead(selectedToRead);
            } else {
                getToReadById(toReadId);
            }
        } else {
            setSelectedToRead(DEFAULT_SELECTED_TO_READ);
        }
    }, [toReads, toReadId]);
    

    const getReviewById = async (reviewId) => {
        try {
            const { data, status } = await axios.get(`/api/e/v1/social/get_review_by_id`, {
                params: {
                    review_id: reviewId
                }
            });
            if (status === 200) {
                const review = data?.review;
                setSelectedReview(review);
            }
        } catch (err) {
            console.error(err);
            setSelectedReview(DEFAULT_SELECTED_REVIEW)
            navigate("/feed");
        }
    };

    const getReadingById = async (readingId) => {
        try {
            const { data, status } = await axios.get(`/api/e/v1/social/get_reading_by_id`, {
                params: {
                    reading_id: readingId
                }
            });
            if (status === 200) {
                const reading = data?.reading;
                setSelectedReading(reading);
            }
        } catch (err) {
            console.error(err);
            setSelectedReview(DEFAULT_SELECTED_READING)
            navigate("/feed");
        }
    };

    const getToReadById = async (toReadId) => {
        try {
            const { data, status } = await axios.get(`/api/e/v1/social/get_to_read_by_id`, {
                params: {
                    toRead_id: toReadId
                }
            });
            if (status === 200) {
                const toRead = data?.toRead;
                setSelectedToRead(toRead);
            }
        } catch (err) {
            console.error(err);
            setSelectedReview(DEFAULT_SELECTED_TO_READ)
            navigate("/feed");
        }
    };

    const handleReviewClick = (reviewId) => {
        navigate(`/feed/reviews/${reviewId}`);
        getReviewById(reviewId);
    };

    const handleReadingClick = (readingId) => {
        navigate(`/feed/readings/${readingId}`);
        getReadingById(readingId);
    };

    const handleToReadClick = (toReadId) => {
        navigate(`/feed/toreads/${toReadId}`);
        getToReadById(toReadId);
    };



    const handleSearchGoogleBooks = async (query) => {
        setIsDataLoading(true);
        try {
            const { data, status } = await axios.get(`/api/e/v1/book/google_books/search`, {
                params: {
                    query
                }
            });
            if (status === 200) {
                const books = data?.books;
                setGoogleBooksBooks(books);
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error searching books");
        } finally {
            setIsDataLoading(DEFAULT_IS_DATA_LOADING);
        }
    };

    const handleAddBook = (book) => {
        setSelections([book]);
        setGoogleBooksBooks([]); // Clear the search results
    };

    const handleRemoveBook = () => {
        setSelections(DEFAULT_SELECTIONS);
    };

    const handlePostReview = async () => {
        try {
            await processBooks();
            await handleCreateReview();
            await getAllFollowingPosts();
            await getAllPosts();
            await getAllSnailMailPosts();
        } catch (err) {
            console.error(err);
            toast.error("Error posting review");
        } finally {
            await handleCloseModal();
        }
    };

    const handlePostReading = async () => {
        try {
            await processBooks();
            await handleCreateReading();
            await getAllFollowingPosts();
            await getAllPosts();
            await getAllSnailMailPosts();
        } catch (err) {
            console.error(err);
            toast.error("Error posting reading");
        } finally {
            await handleCloseReadingModal();
        }
    };

    const handlePostToRead = async () => {
        console.log("entered post to read")
        try {
            await processBooks();
            await handleCreateToRead();
        } catch (err) {
            console.error(err);
            toast.error("Error posting to read");
        } finally {
            await handleCloseToReadModal();
        }
    };

    const processBooks = async () => {
        try {
            const { data, status } = await axios.post("/api/e/v1/book/process_books", {
                selections
            });
            if (status !== 200) {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error posting review");
        }
    };

    const handleCreateReview = async () => {
        try {
            const { data, status } = await axios.post("/api/e/v1/social/create_review", {
                isbn: selections[0].isbn,
                rating,
                content,
                reading_content:"",
                to_read_content:"",
                read_date: readDate
            });
            if (status !== 200) {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error posting review");
        }
    };

    const handleCreateReading = async () => {
        try {
            const { data, status } = await axios.post("/api/e/v1/social/create_reading", {
                isbn: selections[0].isbn,
                content: readingContent,
                to_read_content:"",
                start_date: startDate
            });
            if (status !== 200) {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error posting reading");
        }
    };

    const handleCreateToRead = async () => {
        try {
            const { data, status } = await axios.post("/api/e/v1/social/create_to_read", {
                isbn: selections[0].isbn,
                content: toReadContent,
            });
            if (status !== 200) {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error posting to read");
        }
    };


    const handleCloseModal = async () => {
        try {
            setIsModalOpen(DEFAULT_IS_MODAL_OPEN);
            setModalStep(DEFAULT_MODAL_STEP);
            setGoogleBooksSearch(DEFAULT_GOOGLE_BOOKS_SEARCH);
            setGoogleBooksBooks(DEFAULT_GOOGLE_BOOKS_BOOKS);
            setSelections(DEFAULT_SELECTIONS);
            setRating(DEFAULT_RATING);
            setContent(DEFAULT_CONTENT);
            setReadDate(DEFAULT_READ_DATE);
        } catch (err) {
            console.error(err);
            toast.error("Error closing modal");
        }
    };

    const handleCloseReadingModal = async () => {
        try {
            setIsReadingModalOpen(DEFAULT_IS_READING_MODAL_OPEN);
            setReadingModalStep(DEFAULT_READING_MODAL_STEP);
            setReadingContent(DEFAULT_READING_CONTENT);
            setStartDate(DEFAULT_START_DATE);
        } catch (err) {
            console.error(err);
            toast.error("Error closing review modal");
        }
    };

    const handleCloseToReadModal = async () => {
        try {
            setIsToReadModalOpen(DEFAULT_IS_TO_READ_MODAL_OPEN);
            setToReadModalStep(DEFAULT_TO_READ_MODAL_STEP);
            setToReadContent(DEFAULT_TO_READ_CONTENT);
        } catch (err) {
            console.error(err);
            toast.error("Error closing review modal");
        }
    };

    return (
        <div className="page flex justify-center items-center">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <Header />
                <div className="flex flex-col sm:flex-row justify-center items-center full">
                    <div className="content text-center">
                        {isUserLoading ?
                            <Loader /> :
                            <>
                                {selectedReview ? (
                                    <>
                                        <div className="flex justify-center items-center full">
                                        <img
                                            src="/images/chevron-left.svg"
                                            alt="Back"
                                            onClick={() => {
                                                setSelectedReview(DEFAULT_SELECTED_REVIEW);
                                                navigate(`/feed`);
                                              }}
                                            style={{
                                                position: 'absolute',
                                                top: '20px',
                                                left: '20px',
                                                cursor: 'pointer',
                                                width: '20px',
                                                height: '20px'
                                            }}
                                        />
                                        </div>
                                        <Review selectedReview={selectedReview} refreshReviews={getAllFollowingPosts} navigatePath={"/feed"} />
                                    </>
                                ) : selectedReading ? (
                                    <>
                                        <div className="flex justify-center items-center full">
                                        <img
                                            src="/images/chevron-left.svg"
                                            alt="Back"
                                            onClick={() => {
                                                setSelectedReading(DEFAULT_SELECTED_READING);
                                                navigate(`/feed`);
                                              }}
                                            style={{
                                                position: 'absolute',
                                                top: '20px',
                                                left: '20px',
                                                cursor: 'pointer',
                                                width: '20px',
                                                height: '20px'
                                            }}
                                        />
                                        </div>
                                        <Reading selectedReading={selectedReading} refreshReadings={getAllFollowingPosts} navigatePath={"/feed"} />
                                    </>
                                ) : selectedToRead ? (
                                    <>
                                        <div className="flex justify-center items-center full">
                                        <img
                                            src="/images/chevron-left.svg"
                                            alt="Back"
                                            onClick={() => {
                                                setSelectedToRead(DEFAULT_SELECTED_TO_READ);
                                                navigate(`/feed`);
                                              }}
                                            style={{
                                                position: 'absolute',
                                                top: '20px',
                                                left: '20px',
                                                cursor: 'pointer',
                                                width: '20px',
                                                height: '20px'
                                            }}
                                        />
                                        </div>
                                        <ToRead selectedToRead={selectedToRead} refreshToReads={getAllFollowingPosts} navigatePath={"/feed"} />
                                    </>
                                ) : ( 
                                    <>
                                    <Tabs
                                        defaultValue="explore"
                                        className="full"
                                    >
                                        <TabsListComponent>
                                            <TabsTrigger value="explore">Everyone</TabsTrigger>
                                            {/* <TabsTrigger value="following">Following</TabsTrigger> */}
                                            <TabsTrigger value="snailmail">Snail Mail Club</TabsTrigger>
                                        </TabsListComponent>
                                        <div className="flex justify-center items-center full p-3">
                                            <Popover>
                                                <PopoverTrigger asChild>
                                                    <Button variant="outline">+ New</Button>
                                                </PopoverTrigger>
                                                <PopoverContent className="w-80">
                                                    <div className="grid gap-4">
                                                    <Button variant="outline" onClick={() => setIsToReadModalOpen(true)} >To Read 📚</Button>
                                                    <Button variant="outline" onClick={() => setIsReadingModalOpen(true)} >Reading 📖</Button>
                                                    <Button variant="outline" onClick={() => setIsModalOpen(true)} >Read 📚</Button>
                                                    </div>
                                                </PopoverContent>
                                            </Popover>
                                        </div>
                                        <TabsContent value="explore">
                                            {explorePosts.map(post => (
                                                <div key={post._id}>
                                                    {/* Render each post component based on its type */}
                                                    {post.type === 'toRead' && (
                                                        <ToReads
                                                            toReads={[post]} 
                                                            refreshToReads={getAllPosts}
                                                            handleToReadClick={handleToReadClick}
                                                            page="feed"
                                                        />
                                                    )}
                                                    {post.type === 'reading' && (
                                                        <Readings
                                                            readings={[post]} 
                                                            refreshReadings={getAllPosts}
                                                            handleReadingClick={handleReadingClick}
                                                            page="feed"
                                                        />
                                                    )}
                                                    {post.type === 'review' && (
                                                        <Reviews
                                                            reviews={[post]} 
                                                            refreshReviews={getAllPosts}
                                                            handleReviewClick={handleReviewClick}
                                                            page="feed"
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                        </TabsContent>
                                        <TabsContent value="snailmail">
                                            {snailMailPosts.map(post => (
                                                <div key={post._id}>
                                                    {/* Render each post component based on its type */}
                                                    {post.type === 'toRead' && (
                                                        <ToReads
                                                            toReads={[post]} 
                                                            refreshToReads={getAllSnailMailPosts}
                                                            handleToReadClick={handleToReadClick}
                                                            page="feed"
                                                        />
                                                    )}
                                                    {post.type === 'reading' && (
                                                        <Readings
                                                            readings={[post]} 
                                                            refreshReadings={getAllSnailMailPosts}
                                                            handleReadingClick={handleReadingClick}
                                                            page="feed"
                                                        />
                                                    )}
                                                    {post.type === 'review' && (
                                                        <Reviews
                                                            reviews={[post]} 
                                                            refreshReviews={getAllSnailMailPosts}
                                                            handleReviewClick={handleReviewClick}
                                                            page="feed"
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                        </TabsContent>
                                        <TabsContent value="following">
                                        {followingPosts.map(post => (
                                                <div key={post._id}>
                                                    {/* Render each post component based on its type */}
                                                    {post.type === 'toRead' && (
                                                        <ToReads
                                                            toReads={[post]} 
                                                            refreshToReads={getAllFollowingPosts}
                                                            handleToReadClick={handleToReadClick}
                                                            page="feed"
                                                        />
                                                    )}
                                                    {post.type === 'reading' && (
                                                        <Readings
                                                            readings={[post]} 
                                                            refreshReadings={getAllFollowingPosts}
                                                            handleReadingClick={handleReadingClick}
                                                            page="feed"
                                                        />
                                                    )}
                                                    {post.type === 'review' && (
                                                        <Reviews
                                                            reviews={[post]} 
                                                            refreshReviews={getAllFollowingPosts}
                                                            handleReviewClick={handleReviewClick}
                                                            page="feed"
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                        </TabsContent>
                                    </Tabs>
                                    </>
                                )}
                                <ReviewModal
                                    isModalOpen={isModalOpen}
                                    setIsModalOpen={setIsModalOpen}
                                    modalStep={modalStep}
                                    setModalStep={setModalStep}
                                    googleBooksSearch={googleBooksSearch}
                                    setGoogleBooksSearch={setGoogleBooksSearch}
                                    handleSearchGoogleBooks={handleSearchGoogleBooks}
                                    isDataLoading={isDataLoading}
                                    googleBooksBooks={googleBooksBooks}
                                    selections={selections}
                                    handleAddBook={handleAddBook}
                                    handleRemoveBook={handleRemoveBook}
                                    rating={rating}
                                    setRating={setRating}
                                    content={content}
                                    setContent={setContent}
                                    date={readDate}
                                    setDate={setReadDate}
                                    handlePostReview={handlePostReview}
                                    handleCloseReviewModal={handleCloseModal}
                                />
                                <ReadingModal
                                    isModalOpen={isReadingModalOpen}
                                    setIsModalOpen={setIsReadingModalOpen}
                                    modalStep={readingModalStep} 
                                    setModalStep={setReadingModalStep}
                                    googleBooksSearch={googleBooksSearch} 
                                    setGoogleBooksSearch={setGoogleBooksSearch}
                                    handleSearchGoogleBooks={handleSearchGoogleBooks}
                                    isDataLoading={isDataLoading}
                                    googleBooksBooks={googleBooksBooks}
                                    selections={selections}
                                    handleAddBook={handleAddBook}
                                    handleRemoveBook={handleRemoveBook}
                                    content={readingContent}
                                    setContent={setReadingContent}
                                    date={startDate}
                                    setDate={setStartDate}
                                    handlePostReading={handlePostReading}
                                    handleCloseReadingModal={handleCloseReadingModal}
                                />
                                <ToReadModal
                                    isModalOpen={isToReadModalOpen}
                                    setIsModalOpen={setIsToReadModalOpen}
                                    modalStep={toReadModalStep} 
                                    setModalStep={setToReadModalStep}
                                    googleBooksSearch={googleBooksSearch} 
                                    setGoogleBooksSearch={setGoogleBooksSearch}
                                    handleSearchGoogleBooks={handleSearchGoogleBooks}
                                    isDataLoading={isDataLoading}
                                    googleBooksBooks={googleBooksBooks}
                                    selections={selections}
                                    handleAddBook={handleAddBook}
                                    handleRemoveBook={handleRemoveBook}
                                    content={toReadContent}
                                    setContent={setToReadContent}
                                    handlePostToRead={handlePostToRead}
                                    handleCloseToReadModal={handleCloseToReadModal}
                                />
                            </>
                        }
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Feed;